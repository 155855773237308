import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewOverviewBrands = _resolveComponent("NewOverviewBrands")!
  const _component_OldOverviewBrands = _resolveComponent("OldOverviewBrands")!

  return (_ctx.isNewAdminEnabled)
    ? (_openBlock(), _createBlock(_component_NewOverviewBrands, {
        key: 0,
        brandsList: _ctx.brandsList,
        searchBrands: _ctx.searchBrands,
        handleViewDetails: _ctx.handleViewDetails,
        limit: _ctx.limitCampaigns,
        changePage: _ctx.onPage,
        loading: _ctx.loading,
        getBrandList: _ctx.getOverviewBrandsList,
        skip: _ctx.skipCampaigns
      }, null, 8, ["brandsList", "searchBrands", "handleViewDetails", "limit", "changePage", "loading", "getBrandList", "skip"]))
    : (_openBlock(), _createBlock(_component_OldOverviewBrands, { key: 1 }))
}