import React from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { CloseIcon } from '@/react_app/img/Icons';

import './ConfirmDialog.css';

const ConfirmDialog = (props) => {
    const { open, close, deleteBrands, loading = false } = props;
    return (
        <Dialog visible={open} onHide={() => close()} className='confirmDialog'>
            <div className='closeRow'>
                <div className='closeButton' disabled={loading} onClick={() => close()}><CloseIcon /></div>
            </div>
            <div className='reasonContent'>
                <p className='reasonTitle'>Confirmation</p>
                <p className='reasonText'>Are you sure you want to delete selected brand's</p>

                <div className='reasonButtonGroup'>
                    <Button className='reasonButtonCancel' onClick={() => close()}>Cancel</Button>
                    <Button className='reasonButtonSave' loading={loading} onClick={() => deleteBrands()}>Delete</Button>
                </div>
            </div>
        </Dialog>
    )
}

export default ConfirmDialog
