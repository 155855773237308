import React, { useEffect, useState } from "react";

import { Image } from "primereact/image";
import { Column } from "primereact/column";
import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Paginator } from 'primereact/paginator';

import { useAdmins, useToastModule } from '@/modules';
import CustomLoader from '@/react_app/Other/CustomLoader/CustomLoader';
import ConfirmDialog from '@/react_app/components/Dialogs/ConfirmDialog/ConfirmDialog';
import NewBrandInfoPage from "@/react_app/NewAdmin/NewOverviewBrands/NewBrandInfoPage/NewBrandInfoPage";

import noImage from "../img/no-image.png";

import "./NewOverviewBrands.css";

const brandBodyTemplate = (rowData) => (
  <div className='brandTemplate'>
    <Image className='brandTemplateImage' src={rowData.photo ? rowData.photo : noImage} alt='campaignPhoto' />
    <p className='brandTemplateText'>{rowData.name}</p>
  </div>
);

const emailBodyTemplate = (rowData) => (
  <div className='emailTemplate'>
    {rowData.creator?.email}
  </div>
);

export default function (props) {
  const {
    loading, brandsList, searchBrands, limit, changePage,getBrandList,skip
  } = props;

  const toast = useToastModule();
  const adminsModule = useAdmins();

  const [first, setFirst] = useState(0);
  const [noResults, setNoResults] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const detailsBodyTemplate = (rowData) => (
    <div className='detailsTemplate' onClick={() => setSelectedBrand(rowData)}>
      View Details
    </div>
  );
  const onPageChange = (event) => {
    setFirst(event.first);
    changePage(event)
  };
  useEffect(() => {
    setNoResults(!(brandsList?.brands?.length))
  }, [brandsList]);
  
  const handleSearch = (value) => {
    setFirst(0);
    setSearchValue(value)
    searchBrands(value);
    setSelectedBrands([]);
  }
  const handleConfirmDialog = (flag) => {
    setOpenConfirmDialog(flag);
  }
  const handleDeleteBrands = () => {
    setIsLoading(true);
    const IDs = selectedBrands.map((brand) => brand._id);
    adminsModule.manager.deleteBrands(IDs).then(response => {
      if (response) {
        toast.showSuccess(response.message);
        setIsLoading(false);
        handleConfirmDialog(false);
        setSelectedBrands([]);
        getBrandList(limit,skip,searchValue);
      } else {
        setIsLoading(false);
      }
    }).catch(() => {
      setIsLoading(false);
    });
  }
  const handleSelectBrand = (brandId) => {
    setSelectedBrands(brandId);  
  }

  return (
    <div className={'overview-brands'}>
      {!selectedBrand ?
        <div>
          <p className={'title'}>Overview Brands</p>
          <div className='filterWrapper'>
            <span className="p-input-icon-left inputFieldWrapper">
              <i className="pi pi-search" />
              <InputText placeholder="Search for brand" className={'inputField'} value={searchValue} onChange={(e) => handleSearch(e.target.value)} />
            </span>
            <Button icon="pi pi-trash" className='deleteBtn' onClick={() => handleConfirmDialog(true)} disabled={selectedBrands.length === 0} >Delete</Button>
          </div>
          <DataTable value={brandsList?.brands} emptyMessage={"No brands found."} loading={loading} loadingIcon={<CustomLoader />}
            selectionMode="checkbox" selection={selectedBrands} onSelectionChange={(e) => { handleSelectBrand(e.value) }}
            style={{ minHeight: 500 }}
          >
            <Column selectionMode="multiple" headerStyle={{ width: '3%' }}></Column>
            <Column field={"name"} header={"Brand"} body={brandBodyTemplate} sortable style={{ width: '43%' }}></Column>
            <Column field={"creator.email"} header={"E-mail"} body={emailBodyTemplate} sortable style={{ width: '43%' }}></Column>
            <Column body={detailsBodyTemplate} style={{ width: '14%' }}></Column>
          </DataTable>
          {!noResults && <Paginator first={first} rows={limit} totalRecords={brandsList?.pagination} onPageChange={onPageChange} style={{ background: '#130A23' }} />}
        </div> :
        <NewBrandInfoPage handleReturn={() => setSelectedBrand('')} brand={selectedBrand} />
      }
      <ConfirmDialog open={openConfirmDialog} close={() => handleConfirmDialog(false)} deleteBrands={handleDeleteBrands} loading={isLoading} />
    </div>
  )
}
