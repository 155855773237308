import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { EventBus, useAPI } from '@/modules';

const AUTH_KEY = 'gi-auth-token';
const REFRESH_KEY = 'gi-refresh-token';
const API_URL = process.env.VUE_APP_API_URL;

export default class HTTPService {
  private static instance: AxiosInstance;
  public static badRequestsArray: any[] = [];
  public static refreshTokens: Promise<any> | undefined;

  public static get(): AxiosInstance {
    if (!this.instance) {
      this.instance = axios.create({
        baseURL: API_URL,
      });
    }

    // eslint-disable-next-line consistent-return
    this.instance.interceptors.response.use((response) => response, async (error) => {
      const { response, config } = error;
      if (response?.status === 403 && response?.data?.message === 'You do not have access.') {
        localStorage.removeItem(AUTH_KEY);
        localStorage.removeItem(REFRESH_KEY);
        window.location.href = '/auth/login';
        EventBus.emit('bad-refresh-error'); // Notify other components if needed
        return Promise.reject(error);
        
      }  
      if (
        response?.status === 401
          && response?.data?.message === 'Invalid refresh'
      ) {
        EventBus.emit('bad-refresh-error');
        return Promise.reject(error);
      }

      if (response?.data?.message !== 'Bad auth token') {
        return Promise.reject(error);
      }

      this.badRequestsArray.push(config);
      if (!config?.retry) {
        config.retry = true;
        // Use a 'clean' instance of axios without the interceptor to refresh the token. No more infinite refresh loop.
        const token = localStorage.getItem(REFRESH_KEY);
        const { execute: refreshReq } = useAPI('', false);
        const refresh = () => refreshReq({
          url: '/auth/refresh',
          method: 'POST',
          data: { refresh: token },
        });

        if (!this.refreshTokens) {
          this.refreshTokens = refresh();
        }
        const refreshData = await this.refreshTokens;

        if (!refreshData || !refreshData?.tokens) {
          return EventBus.emit('bad-refresh-error');
        }
        if (refreshData?.response?.data?.message === 'Invalid refresh') {
          return EventBus.emit('bad-refresh-error');
        }

        // If you are using localStorage, update the token and Authorization header here
        if (refreshData.tokens) {
          localStorage.setItem(AUTH_KEY, refreshData.tokens.access.token);
          localStorage.setItem(REFRESH_KEY, refreshData.tokens.refresh.token);
          HTTPService.setAuthorizationHeader(refreshData.tokens.access.token);
        }
        config.headers.Authorization = `Bearer ${refreshData.tokens.access.token}`;
        this.badRequestsArray.splice(0, 1);
        if (this.badRequestsArray.length === 0) {
          this.refreshTokens = undefined;
        }
        return this.instance.request(config);
      }
    });
    return this.instance;
  }

  public static setAuthorizationHeader(token: string): void {
    const instance = HTTPService.get();
    instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
}
